.form {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.row {
  display: flex;
  width: 100%;
}

.input-div {
  background: #d4e7ff;
  width: 100%;
  padding: 5px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
}

.input-box {
  background: #d4e7ff;
  border: none;
  border-radius: 3px;
  margin-top: 5px;
  color: rgb(49, 49, 49);
}
.input-box:focus {
  outline: none;
}
.btn-div {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}
.post-btn {
  border: 2px solid #659bdb;
  border-radius: 5px;
  background-color: #659bdb;
  padding: 5px px;
  color: #ffffff;
  font-weight: bolder;
  margin-right: 8px;
  font-size: 16px;
  cursor: pointer;
}
.post-btn:active {
  outline: none;
}

.cancel-btn {
  color: rgb(139, 139, 139);
  text-align: center;
  background-size: 200% auto;
  border-radius: 5px;
  padding: 5px 10px;
  border: 2px solid rgb(139, 139, 139);
  font-weight: bolder;
  font-size: 16px;
  cursor: pointer;
  margin-right: 18px;
}
.cancel-btn:focus {
  outline: 0;
}
